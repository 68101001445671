import React from 'react';
import ImagesProvider from '@/contexts/images';
import { Seo } from '@/components';
import { Root } from '@/layouts';
import Hero from '@/layouts/Funcionalidades/Hero';
import { ConhecaMais, ConhecaPlanos, Detail } from '@/layouts/Funcionalidades';
import FUNCIONALIDADES from '@/constants/funcionalidades';
import { MarketingVendasDetails } from '@/constants/pageDetails';

import vendas from '@/assets/images/ferramenta-de-vendas-simples-dental.svg';
import { buttonEvents } from '@/constants/analytics';
import ConhecaBlog from '@/layouts/Funcionalidades/Conheca-blog';

const schema = {
  '@context': 'https://schema.org/',
  '@type': 'BreadcrumbList',
  itemListElement: [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Home',
      item: 'https://www.simplesdental.com/',
    },
    {
      '@type': 'ListItem',
      position: 2,
      name: 'Vendas',
    },
  ],
};

const MarketingVendas = () => {
  const name = FUNCIONALIDADES.marketingVendas.name;

  return (
    <ImagesProvider>
      <Seo
        title="Software para dentistas venderem mais"
        description="Desenvolva estratégias eficazes de marketing e vendas para a sua clínica odontológica com a Simples Dental. Descubra ferramentas, dicas e recursos para impulsionar o crescimento do seu negócio e alcançar mais pacientes."
        schema={schema}
      />
      <Root
        variant="light"
        hero={
          <Hero
            title={name}
            desc="Fidelize clientes e venda mais"
            img={vendas}
            alt="Marketing e vendas para dentistas"
            imageTitle="Ferramenta de Vendas do Simples Dental"
            gaMetadata={{ buttonEvent: buttonEvents.marketingVendas }}
            width={690}
            height={405}
          />
        }
      >
        {MarketingVendasDetails.map((i, index) => (
          <Detail item={i} index={index} key={index} />
        ))}

        <ConhecaPlanos gaMetadata={{ buttonEvent: buttonEvents.marketingVendas }} />

        <ConhecaBlog />

        <ConhecaMais
          page={name}
          gaMetadata={{ saibaMaisButtonEvent: buttonEvents.marketingVendas }}
        />
      </Root>
    </ImagesProvider>
  );
};

export default MarketingVendas;
