import dicasInfaliveis from '@/assets/images/blog-marketing-odontologico-5-dicas-infaliveis-para-atrair-mais-pacientes.png';
import marketingDigital from '@/assets/images/blog-guia-de-marketing-digital-para-dentistas.png';
import estrategias from '@/assets/images/blog-7-estrategias-de-marketing-odontologico-que-vao-alavancar-sua-clinica.png';

export interface ConhecaBlogItem {
  id: number;
  title: string;
  link: string;
  image: string;
}

export const conhecaBlog: ConhecaBlogItem[] = [
  {
    image: dicasInfaliveis,
    id: 1,
    title: 'Marketing odontológico: 5 dicas infalíveis para atrair mais pacientes',
    link: 'marketing-odontologico/',
  },
  {
    image: marketingDigital,
    id: 2,
    title: 'Guia de marketing digital para dentistas',
    link: 'marketing-digital-para-dentistas/',
  },
  {
    image: estrategias,
    id: 3,
    title: '7 estratégias de marketing odontológico que vão alavancar sua clínica',
    link: '7-estrategias-de-marketing-odontologico/',
  },
];

export default conhecaBlog;
