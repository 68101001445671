import Button from '@/components/Button';
import { links } from '@/constants/links';
import { navigate } from 'gatsby';
import React from 'react';
import { Container } from 'react-bootstrap';
import * as styles from './conheca-blog.module.scss';
import conhecaBlogConstants from '@/layouts/Funcionalidades/Conheca-blog/constants';
import Card from './Card';

const ConhecaBlog = () => {
  const handleClick = () => {
    navigate(links.blog);
  };

  return (
    <Container>
      <div className={styles.container}>
        <div>
          <h2 className={styles.title}>Entenda melhor sobre Marketing Odontológico</h2>
          <p className={styles.desc}>Veja os artigos do nosso blog que preparamos para você ;)</p>
        </div>

        <Button variant="primary" onClick={handleClick}>
          <span className={styles.button}>Ver blog completo</span>
        </Button>
      </div>
      <div className={styles.cardsContainer}>
        {conhecaBlogConstants.map(c => (
          <Card card={c} key={c.id} />
        ))}
      </div>
      <div className={styles.buttonFooter}>
        <Button variant="primary" onClick={handleClick}>
          <span className={styles.button}>Ver blog completo</span>
        </Button>
      </div>

      <hr className={styles.divider} />
    </Container>
  );
};

export default ConhecaBlog;
