import React from 'react';
import { ConhecaBlogItem } from './constants';
import * as styles from './conheca-blog.module.scss';
import { links } from '@/constants/links';

export interface CardProps {
  card: ConhecaBlogItem;
}

const Card = ({ card }: CardProps) => {
  const handleClick = (link: string) => {
    const fullLink = links.blog + link;
    window.open(fullLink);
  };

  return (
    <>
      <div className={styles.card}>
        <div>
          <img src={card.image} alt={card.title} />
          <p className={styles.title}>{card.title}</p>
        </div>
        <p className={styles.link} onClick={() => handleClick(card.link)}>
          ler post
        </p>
      </div>
    </>
  );
};

export default Card;
